import React from 'react';
import { Link, graphql } from 'gatsby';
import {
	ButtonContainer,
	MessageDiv,
	StyledArticle,
	StyledBottomLinks,
	StyledButton,
	StyledSuperArticle,
} from '../styles';

import { SEO } from '../components/SEO';
import { Layout } from '../components/Layout';
import { StyledH2 } from '../styles/common';

function BlogListTemplate({ data, pageContext }: any) {
	const { allMarkdownRemark } = data;
	const { edges } = allMarkdownRemark;
	const { currentPage, numPages } = pageContext;
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages - 1;
	const prevPage = currentPage - 1 === 1 ? '/' : `/${currentPage - 1}`;
	const nextPage = `/${currentPage + 1}`;

	return (
		<Layout>
			{edges.map(({ node }: any) => {
				const { id, html, frontmatter } = node;
				const { title, color, button, slug, highlighted } = frontmatter;
				return highlighted ? (
					<StyledSuperArticle color={color} key={id}>
						{title ? <StyledH2 color={color}>{title}</StyledH2> : null}
						<MessageDiv dangerouslySetInnerHTML={{ __html: html }} />
						{button !== '' ? (
							<ButtonContainer>
								<StyledButton as="a" href={slug}>
									{button}
								</StyledButton>
							</ButtonContainer>
						) : null}
					</StyledSuperArticle>
				) : (
					<StyledArticle color={color} key={id}>
						{title ? <StyledH2 color={color}>{title}</StyledH2> : null}
						<MessageDiv dangerouslySetInnerHTML={{ __html: html }} />
						{button !== '' ? (
							<ButtonContainer>
								<StyledButton as="a" href={slug}>
									{button}
								</StyledButton>
							</ButtonContainer>
						) : null}
					</StyledArticle>
				);
			})}

			<StyledBottomLinks>
				{!isLast && (
					<Link to={nextPage} rel="next">
						Następna strona →
					</Link>
				)}
				{!isFirst && (
					<Link to={prevPage} rel="prev">
						← Poprzednia strona
					</Link>
				)}
			</StyledBottomLinks>
		</Layout>
	);
}

export default BlogListTemplate;

export const pageQuery = graphql`
	query blogListQuery($skip: Int!, $limit: Int!) {
		allMarkdownRemark(
			sort: { fields: [frontmatter___position], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					id
					html
					frontmatter {
						slug
						title
						color
						button
						highlighted
					}
				}
			}
		}
	}
`;
